// Such export is called Tree Shaking. It allows to import only the components
// that are needed while webpack will remove the rest of the code from the bundle.
export { Audio } from './loader/audio'
export { BallTriangle } from './loader/ball-triangle'
export { Bars } from './loader/bars'
export { Circles } from './loader/circles'
export { CirclesWithBar } from './loader/circles-with-bar'
export { Grid } from './loader/grid'
export { Hearts } from './loader/hearts'
export { InfinitySpin } from './loader/infinity-spin'
export { LineWave } from './loader/line-wave'
export { MutatingDots } from './loader/mutating-dots'
export { Oval } from './loader/oval'
export { Puff } from './loader/puff'
export { RevolvingDot } from './loader/revolving-dot'
export { Rings } from './loader/rings'
export { RotatingSquare } from './loader/rotating-square'
export { RotatingLines } from './loader/rotating-lines'
export { TailSpin } from './loader/tail-spin'
export { ThreeCircles } from './loader/three-circles'
export { ThreeDots } from './loader/three-dots'
export { Triangle } from './loader/triangle'
export { Watch } from './loader/watch'
export { FallingLines } from './loader/falling-lines'
export { Vortex } from './loader/vortex'
export { RotatingTriangles } from './loader/rotating-triangles'
export { Radio } from './loader/radio'
export { ProgressBar } from './loader/progress-bar'
export { MagnifyingGlass } from './loader/magnifying-glass'
export { FidgetSpinner } from './loader/fidget-spinner'
export { DNA } from './loader/dna'
export { Discuss } from './loader/discuss'
export { ColorRing } from './loader/color-ring'
export { Comment } from './loader/comment'
export { Blocks } from './loader/blocks'
export { Hourglass } from './loader/hourglass'
